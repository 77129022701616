body {
  margin: 0;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

code {
     font-size: 14px;
}

.lendela-overlay {
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 999;
}

.lendela-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 15px;
}

.lendela-container {
  width: 345px;
  max-width: 100%;
  background: #fff;
  padding: 20px;
  border-radius: 15px;
  text-align: center;
  position: relative;
}

.closeBtnLendela {
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 20px;
  color: #717171;
  text-decoration: none;
  line-height: normal;
}

.closeBtnLendela:hover {
  text-decoration: none;
}

.lendela-container h4 {
  font-size: 22px;
  font-weight: 700;
  margin: 10px 0px;
}

.lendela-container p {
  font-size: 14px;
  color: #5f5f60;
}

.lendelaBtn {
  background: rgb(65, 171, 69);
  background: linear-gradient(
    280deg,
    rgba(65, 171, 69, 1) 0%,
    rgba(30, 181, 152, 1) 100%
  );
  width: 100%;
  display: inline-block;
  border-radius: 5px;
  padding: 10px;
  color: #fff;
  font-weight: bold;
  margin-top: 20px;
  text-transform: uppercase;
  font-size: 14px;
}

.lendelaBtn:hover {
  color: #fff;
  text-decoration: none;
}

.calender-Input::-webkit-calendar-picker-indicator {
  display: none;
}

.calender-Input::-moz-calendar-picker-indicator {
  display: none;
}

.calender-Input::-ms-clear {
  display: none;
}

.calender-Input::-ms-placeholder {
  color: white;
}


datepicker-modal {
  position: fixed;
}

.datepicker.default {
    border-radius: 15px;
    background-color: #ffffff;
  }
.datepicker.default .datepicker-header {
  color: #fff;
  background: #00467f;
  border-radius: 15px 15px 0px 0px;
  padding: 0px;
}
.datepicker-col-1.secHeading {
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1.2px;
}
.datepicker-col-1.secHeading + .datepicker-col-1.secHeading {
  border-left: 1px solid #2f5f86;
}
.datepicker .datepicker-navbar {
  padding: .5em .5em .5em .5em;
  background: #eef7ff;
}
.datepicker.default .datepicker-navbar-btn:first-child {
  background: #f47621;
  border-radius: 15px;
  color: #fff;
}
.datepicker.default .datepicker-navbar-btn {
  color: #7bbcf7;
  text-transform: uppercase;
  font-weight: 600;
}
.datepicker.default .datepicker-wheel {
  border-top: 2px solid #f47621;
  border-bottom: 2px solid #f47621;
}

.datepicker .datepicker-scroll > li {
  font-size: 18px;
  font-weight: 400;
}

@media screen and (min-width: 768px) {
  .datepicker.default {    
    position: relative;
    left: auto;
    width: 500px;
    max-width:100%;
  }
.datepicker .datepicker-navbar {
  border-radius: 0px 0px 15px 15px;
}


}