
.advertisePage {
    min-height: 100vh;
    padding: 110px 20px 40px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f8fcff;
    .advertiseContainer {
        width: 800px;
        max-width: 100%;
        margin: auto;
        box-shadow: 0px 0px 15px #004a8e1f;
        border-radius: 10px;
        overflow: hidden;
        padding: 40px;
        background: #fff;
        .advertisementText{
            h2{
                font-size: 2.2rem;
                font-weight: 600;
                margin-bottom: 35px;
            }
        }
        .advertisementForm{
            width: 400px;
            float: right;
            max-width: 100%;
        }
        .advertisementForm label{
            font-size:14px;
        }
        .form-control{
            font-size: 0.8rem;
            min-height: 44px;
        }
        svg{
            .cls-1{fill:#f5f5f5;}
            .cls-2{fill:#e0e0e0;}
            .cls-3{fill:#fff;}
            .cls-4{fill:#ebebeb;}
            .cls-5{fill:#263238;}
            .cls-6{fill:#ffbe9d;}
            .cls-7{fill:#eb996e;}
            .cls-8{fill:#455a64;}
            .cls-9{fill:#004a8e;}
            .cls-10{opacity:0.3;}
        }
        .btn.btn-success{
            background-color: #68bd48;
            border-color:#68bd48;
            padding: 10px 30px;
            text-transform: uppercase;
            margin-top: 18px;
            width: 100%;
        }
    }
    
}



@media (max-width: 767px){
    
    .advertisePage {
        .advertiseContainer {
            .advertisementText {
                svg{
                    display: none;
                }
            }
            .advertisementForm{
                float: none;
                margin: auto;
                width: 100%;
            }
        }
    }
}